import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import api from '../../data/api';
import { Auth } from 'aws-amplify';
import React, { useState, useRef } from 'react';
import moment from 'moment';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

const Notifications = () => {
  const [messages, setMessages] = useState([]);
  const history = useHistory();
  const currentLanguage = useSelector((state) => state.user.currentLanguage);
  const [header, setHeader] = useState('Notifications');
  const [emptyMessage, setEmptyMessage] = useState(
    'You have 0 unread notifications'
  );
  const [clearMessage, setClearMessage] = useState('Clear all notifications');
  const [clearingMessages, setClearingMessages] = useState(false);
  const { addToast } = useToasts();

  // TODO: Determine how we want to notify the users if getNotifications fails
  React.useEffect(() => {
    //there was an issue that this call was being made before bearer token was set -- so just delaying the call for 1 second so bearer gets set
    setTimeout(async () => {
      const response = await api.post(
        `${process.env.REACT_APP_API_BASE}/user/getNotifications`,
        { currentLanguage }
      );
      setMessages(response.data.notes);
      setHeader(response.data.header);
      setEmptyMessage(response.data.emptyMessage);
      setClearMessage(response.data.clearMessage);
    }, 1000);
  }, []);

  useInterval(async () => {
    try {
      const response = await api.post(
        `${process.env.REACT_APP_API_BASE}/user/getNotifications`,
        { currentLanguage }
      );
      setMessages(response.data.notes);
      setHeader(response.data.header);
      setEmptyMessage(response.data.emptyMessage);
      setClearMessage(response.data.clearMessage);
    } catch (err) {
      console.log(err);
    }
  }, 60000);

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest function.
    React.useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    React.useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  const markRead = async (id) => {
    try {
      let filtered = messages.filter((m) => m._id !== id);
      setMessages(filtered);
      await api.post(
        `${process.env.REACT_APP_API_BASE}/user/readNotification`,
        { notificationid: id }
      );
    } catch {
      addToast('There was an error marking this notification as read', {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 10000,
      });
    }
  };

  const markAllRead = async () => {
    try {
      setClearingMessages(true);
      const allMessages = [...messages];
      const allPromises = [];
      allMessages.forEach((m) =>
        allPromises.push(
          api.post(`${process.env.REACT_APP_API_BASE}/user/readNotification`, {
            notificationid: m._id,
          })
        )
      );
      await Promise.all(allPromises);
      setMessages([]);
      setClearingMessages(false);
    } catch {
      addToast('There was an error marking one or more notifications as read', {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 10000,
      });
    }
  };

  const popover = (
    <Popover>
      <Popover.Content>
        <div className="d-flex align-items-center justify-content-between">
          <span className="mt-2 mb-2">
            <strong>{header}</strong>
          </span>
          {messages && messages.length > 0 && (
            <span>
              <span
                className="btn btn-sm btn-secondary notifications-btn"
                style={{ cursor: 'pointer' }}
                onClick={markAllRead}
              >
                {clearMessage}
              </span>
              {clearingMessages && (
                <FontAwesomeIcon
                  icon={['fas', 'spinner']}
                  color="#00709B"
                  spin
                />
              )}
            </span>
          )}
        </div>
        <div className="notifications-wrapper">
          {messages.length === 0 ? (
            <p>{emptyMessage}</p>
          ) : (
            messages &&
            messages.length > 0 &&
            messages.map((m, i) => (
              <div
                className="clear-fix border-top pt-1 mb-1 d-flex justify-content-between align-items-center"
                key={`message-${m._id}-${i}`}
              >
                {m.type === 'postShare' && (
                  <span>
                    <a
                      href="#"
                      className="notification-name"
                      aria-label={`${m.fromUsername}'s profile`}
                      onClick={() => history.push(`/profile/${m.fromUserId}`)}
                    >
                      {m.fromUsername}
                    </a>
                    &nbsp;
                    {m.message} <br />
                    {m.postTitle}
                    <br />
                    in{' '}
                    <strong>
                      <a
                        href="#"
                        className="notification-bold"
                        aria-label={`${m.collectionTitle} collection`}
                        onClick={() =>
                          history.push(`/collection/${m.collectionId}`)
                        }
                      >
                        {m.collectionTitle}
                      </a>
                    </strong>
                    <br />
                    <span className="notification-date">
                      {moment(m.created).format('MMMM DD, YYYY')}
                    </span>
                  </span>
                )}
                {m.type === 'collectionFollow' && (
                  <span>
                    <a
                      href="#"
                      className="notification-name"
                      aria-label={`${m.fromUsername}'s profile`}
                      onClick={() => history.push(`/profile/${m.fromUserId}`)}
                    >
                      {m.fromUsername}
                    </a>
                    &nbsp;
                    {m.message}
                    <br />{' '}
                    <strong>
                      <a
                        href="#"
                        className="notification-bold"
                        aria-label={`${m.collectionTitle} collection`}
                        onClick={() =>
                          history.push(`/collection/${m.collectionId}`)
                        }
                      >
                        {m.collectionTitle}
                      </a>
                    </strong>
                    <br />
                    <span className="notification-date">
                      {moment(m.created).format('MMMM DD, YYYY')}
                    </span>
                  </span>
                )}
                {m.type === 'followUser' && (
                  <span>
                    <a
                      href="#"
                      className="notification-name"
                      aria-label={`${m.fromUsername}'s profile`}
                      onClick={() => history.push(`/profile/${m.fromUserId}`)}
                    >
                      {m.fromUsername}
                    </a>
                    &nbsp;
                    {m.message}
                    <br />
                    <span className="notification-date">
                      {moment(m.created).format('MMMM DD, YYYY')}
                    </span>
                  </span>
                )}
                {m.type === 'likePost' && (
                  <span>
                    <a
                      href="#"
                      className="notification-name"
                      aria-label={`${m.fromUsername}'s profile`}
                      onClick={() => history.push(`/profile/${m.fromUserId}`)}
                    >
                      {m.fromUsername}
                    </a>
                    &nbsp;
                    {m.message} <br />
                    {m.postTitle}
                    <br />
                    in{' '}
                    <strong>
                      <a
                        href="#"
                        className="notification-bold"
                        aria-label={`${m.collectionTitle} collection`}
                        onClick={() =>
                          history.push(`/collection/${m.collectionId}`)
                        }
                      >
                        {m.collectionTitle}
                      </a>
                    </strong>
                    <br />
                    <span className="notification-date">
                      {moment(m.created).format('MMMM DD, YYYY')}
                    </span>
                  </span>
                )}
                {m.type === 'likeCollection' && (
                  <span>
                    <a
                      href="#"
                      className="notification-name"
                      aria-label={`${m.fromUsername}'s profile`}
                      onClick={() => history.push(`/profile/${m.fromUserId}`)}
                    >
                      {m.fromUsername}
                    </a>
                    &nbsp;
                    {m.message}
                    <br />{' '}
                    <strong>
                      <a
                        href="#"
                        className="notification-bold"
                        aria-label={`${m.collectionTitle} collection`}
                        onClick={() =>
                          history.push(`/collection/${m.collectionId}`)
                        }
                      >
                        {m.collectionTitle}
                      </a>
                    </strong>
                    <br />
                    <span className="notification-date">
                      {moment(m.created).format('MMMM DD, YYYY')}
                    </span>
                  </span>
                )}

                {m.type === 'savedPost' && (
                  <span>
                    <a
                      href="#"
                      className="notification-name"
                      aria-label={`${m.fromUsername}'s profile`}
                      onClick={() => history.push(`/profile/${m.fromUserId}`)}
                    >
                      {m.fromUsername}
                    </a>
                    &nbsp;
                    {m.message}
                    <br />
                    {m.postTitle} to collection{' '}
                    <strong>
                      <a
                        href="#"
                        aria-label={`${m.collectionTitle} collection`}
                        onClick={() =>
                          history.push(`/collection/${m.collectionId}`)
                        }
                      >
                        {m.collectionTitle}
                      </a>
                    </strong>
                    <br />
                    <span className="notification-date">
                      {moment(m.created).format('MMMM DD, YYYY')}
                    </span>
                  </span>
                )}
                {m.type === 'collectionPostAdd' && (
                  <span>
                    <a
                      href="#"
                      className="notification-name"
                      aria-label={`${m.fromUsername}'s profile`}
                      onClick={() => history.push(`/profile/${m.fromUserId}`)}
                    >
                      {m.fromUsername}
                    </a>
                    &nbsp;
                    {m.message} <br />
                    {m.postTitle}&nbsp; to your{' '}
                    <strong>
                      <a
                        href="#"
                        aria-label={`${m.collectionTitle} collection`}
                        onClick={() =>
                          history.push(`/collection/${m.collectionId}`)
                        }
                      >
                        {m.collectionTitle}
                      </a>
                    </strong>{' '}
                    collection
                    <br />
                    <span className="notification-date">
                      {moment(m.created).format('MMMM DD, YYYY')}
                    </span>
                  </span>
                )}
                {/* {m.type === 'verificationApproval' &&
                  <span>
                    {m.fromUsername} <br/>
                    Approved Verification for <a href="#" onClick={() => history.push(`/post/${m.collectionId}`)}>{m.collectionTitle}</a><br/>
                    {moment(m.created).format("MMMM DD, YYYY")}
                  </span>
                }
                {m.type === 'verificationDenial' &&
                  <span>
                    {m.fromUsername} <br/>
                    Denied Verification for <a href="#" onClick={() => history.push(`/post/${m.collectionId}`)}>{m.collectionTitle}</a><br/>
                    {moment(m.created).format("MMMM DD, YYYY")}
                </span>
                } */}
                {m.type === 'collectionShare' && (
                  <span>
                    <a
                      href="#"
                      className="notification-name"
                      aria-label={`${m.fromUsername}'s profile`}
                      onClick={() => history.push(`/profile/${m.fromUserId}`)}
                    >
                      {m.fromUsername}
                    </a>
                    &nbsp;
                    {m.message}
                    <br />
                    <strong>
                      <a
                        href="#"
                        aria-label={`${m.collectionTitle} collection`}
                        onClick={() =>
                          history.push(`/collection/${m.collectionId}`)
                        }
                      >
                        {m.collectionTitle}
                      </a>
                    </strong>
                    <br />
                    <span className="notification-date">
                      {moment(m.created).format('MMMM DD, YYYY')}
                    </span>
                  </span>
                )}

                <span>
                  <button
                    className="btn btn-secondary btn-sm ml-3 mt-2 mb-2"
                    onClick={() => markRead(m._id)}
                  >
                    X
                  </button>
                </span>
              </div>
            ))
          )}
        </div>
      </Popover.Content>
    </Popover>
  );

  return (
    <div className="ml-3 notification-wrapper">
      <OverlayTrigger
        trigger="click"
        rootClose
        placement="bottom"
        overlay={popover}
      >
        <button className="btn">
          <FontAwesomeIcon icon={['fal', 'bell']} className="fa-2x" />
          <div className="notification-count">{messages.length}</div>
        </button>
      </OverlayTrigger>
    </div>
  );
};

export default Notifications;

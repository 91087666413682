import authActions from '../actions/authenticated';
import resetActions from '../actions/reset';
import api from '../../data/api';

const initialState = {
  auth: true,
};

const onlineReducer = (state = initialState, action) => {
  switch (action.type) {
    case authActions.SET_AUTH:
      return { ...state, auth: action.payload };
    case resetActions.RESET_ALL:
      api.clearToken();
      return initialState;
    default:
      return state;
  }
};

export default onlineReducer;

import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken');
    console.log('Interceptor - Token from localStorage:', token);
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
      console.log(
        'Interceptor - Set Authorization header:',
        config.headers['Authorization']
      );
    } else {
      console.log('Interceptor - No token found in localStorage');
    }
    console.log('Interceptor - Final config headers:', config.headers);
    return config;
  },
  (error) => {
    console.error('Interceptor - Request error:', error);
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    console.log('Interceptor - Response headers:', response.config.headers);
    return response;
  },
  (error) => {
    console.error('Interceptor - Response error:', error);
    return Promise.reject(error);
  }
);

api.clearToken = () => {
  localStorage.removeItem('authToken');
  delete api.defaults.headers.common['Authorization'];
};

export default api;
